var default_width = window.innerWidth;//アクセスした時の横幅を取得 スクロールバーを含めた値
var resize_width = window.innerWidth;
var break_point = 1000;
$(function() {
	var default_height = $(document).innerHeight();//アクセスした時の高さを取得
  $(window).on('load',function() {
    $("body").addClass("load");
  });
  
  //inview（画面内に入ったら発火する）
  var ignition = function() { 
    $('.js-inview').on('inview', function(event, isInView, visiblePartX, visiblePartY) {
      $(this).stop().addClass('active');
    });
  };
  setTimeout(function() {
    ignition();
  },1000);

  //スムーズスクロール（ページ内リンク）
  var headerHeight = $('.header').outerHeight();
  var urlHash = location.hash;
  if(urlHash) {
      $('body,html').stop().scrollTop(0);
      setTimeout(function(){
          var target = $(urlHash);
          var position = target.offset().top - headerHeight - 50;
          $('body,html').stop().animate({scrollTop:position}, 600,"swing");
      }, 100);
  }

  $("a[href^='#'], .js-scroll").on("click", function () {
    var f = 600;
    var e = $(this).attr("href");
    var g = $(e == "#" || e == "" ? "html" : e);
    var h = g.offset().top;
    $("html, body").animate({
      scrollTop: h
    }, f, "swing");
    return false
  });
});